<template>
  <div class="category-page clearfix">
    <van-nav-bar title="全部产品" left-arrow  @click-left="$router.go(-1)"/>
    <van-sticky>
      <div class="side-nav">
        <div class="item" @click="chooseClass(index)" :class="{current: selectedCategory==index}" v-for="(category, index) in categories" :key="index">{{ category }}</div>
      </div>
    </van-sticky>
    <div class="right-content content-padding">
      <div v-for="(item, index) in caegoryProducts" :key="index">
        <div v-if="selectedCategory==index">
          <div class="banner">
            <router-link :to="item.banner_link"><img :src="item.banner" alt=""></router-link>
          </div>

          <div class="panel" v-for="(product, key) in item.products" :key="key">
            <h2>{{ product.title }}</h2>
            <div class="products">
              <div class="item" v-for="(product, productIndex) in product.products" :key="productIndex" @click="$router.push(product.link)">
                <img :src="product.image" alt="">
                <span class="name" v-html="product.name"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CDN_URL } from '../config'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'category',
  components: {
  },
  data () {
    return {
      selectedCategory: 0,
      categories: [
        '智能手表',
        '儿童手表',
        '耳机',
        '配件'
      ],
      caegoryProducts: [
        {
          banner: 'https://site-cdn.huami.com/files/amazfit/mobile/images/product/gtr4/category.jpg',
          banner_link: 'products/gtr4',
          products: [
            {
              title: '都市时尚',
              products: [
                {
                  name: 'Amazfit<br>GTR 4',
                  image: 'https://site-cdn.huami.com/files/amazfit/mobile/images/product/gtr4/hots-gtr4.png',
                  link: '/products/gtr4'
                },
                {
                  name: 'Amazfit<br>GTS 4',
                  image: 'https://site-cdn.huami.com/files/amazfit/mobile/images/product/gts4/hots-gts4.png',
                  link: '/products/gts4'
                },
                {
                  name: 'Amazfit<br>GTR 3 Pro',
                  image: 'https://site-cdn.huami.com/files/amazfit/cn/poster/gtr3-pro.png',
                  link: '/products/gtr3-pro'
                },
                {
                  name: 'Amazfit<br>GTR 3',
                  image: 'https://site-cdn.huami.com/files/amazfit/cn/poster/黑色220.png',
                  link: '/products/gtr3'
                },
                {
                  name: 'Amazfit<br>GTS 3',
                  image: 'https://site-cdn.huami.com/files/amazfit/cn/poster/GTS3-1-薄雾白-220.png',
                  link: '/products/gts3'
                },
                {
                  name: 'Amazfit<br>GTR 2 eSIM',
                  image: 'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e-sim/1_pro_01.png',
                  link: '/products/gtr2e-sim'
                },
                {
                  name: 'Amazfit<br>GTS 2e',
                  image: 'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/1_pro_01.png',
                  link: '/products/gts2e'
                },
                {
                  name: 'Amazfit<br>GTR 2e',
                  image: 'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/1_pro_01.png',
                  link: '/products/gtr2e'
                },
                {
                  name: 'Amazfit<br>GTS 2 mini',
                  image: 'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2-mini/3_pro_01.png',
                  link: '/products/gts2-mini'
                },
                {
                  name: 'Amazfit<br>GTS 2',
                  image: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/3_pro_01.png',
                  link: '/products/gts2'
                },
                {
                  name: 'Amazfit<br>GTR 2',
                  image: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gtr2/1_pro_01.png',
                  link: '/products/gtr2'
                },
                {
                  name: 'Amazfit<br>GTS',
                  image: CDN_URL + '/images/category/watch/gts.png',
                  link: '/products/gts'
                },
                {
                  name: 'Amazfit<br>GTR',
                  image: CDN_URL + '/images/category/watch/gtr.png',
                  link: '/products/gtr'
                },
                {
                  name: 'Amazfit GTR<br/>璀璨特别版',
                  image: CDN_URL + '/images/category/watch/gtr-special.png',
                  link: '/products/gtr-special'
                },
                {
                  name: 'Amazfit GTR<br/>钢铁侠系列限量版',
                  image: CDN_URL + '/images/category/watch/gtr-iron.png',
                  link: '/products/gtr-iron'
                }
              ]
            },
            {
              title: '专业运动',
              products: [
                {
                  name: 'Amazfit<br>智能运动手表 3 精英版',
                  image: CDN_URL + '/images/category/watch/stratos-3-lite.png',
                  link: '/products/stratos-3-lite'
                },
                {
                  name: 'Amazfit<br>智能运动手表 3 星球大战系列限量版',
                  image: CDN_URL + '/images/category/watch/stratos-3-starwar.png',
                  link: '/products/stratos-3-starwar'
                },
                {
                  name: 'Amazfit<br>智能运动手表 3',
                  image: CDN_URL + '/images/category/watch/stratos-3.png',
                  link: '/products/stratos-3'
                },
                {
                  name: 'Amazfit<br>智能运动手表 2',
                  image: CDN_URL + '/images/category/watch/stratos.png',
                  link: '/products/stratos'
                },
                {
                  name: 'Amazfit<br>智能运动手表 2S',
                  image: CDN_URL + '/images/category/watch/stratos+.png',
                  link: '/products/stratos+'
                },
                {
                  name: 'Amazfit<br>智能运动手表',
                  image: CDN_URL + '/images/category/watch/pace.png',
                  link: '/products/pace'
                }
              ]
            },
            {
              title: '户外探索',
              products: [
                {
                  name: 'Amazfit<br/> T-Rex Pro',
                  image: 'https://site-cdn.huami.com/files/amazfit/cn/sku/t-rex-pro/1_pro_01.png',
                  link: '/products/t-rex-pro'
                },
                {
                  name: 'Amazfit<br/> Neo',
                  image: CDN_URL + '/images/category/watch/neo.png',
                  link: '/products/neo'
                },
                {
                  name: 'Amazfit<br/> Ares',
                  image: CDN_URL + '/images/category/watch/ares.png',
                  link: '/products/ares'
                },
                {
                  name: 'Amazfit<br/> T-Rex',
                  image: CDN_URL + '/images/category/watch/t-rex.png',
                  link: '/products/t-rex'
                }
              ]
            },
            {
              title: '全能旗舰',
              products: [
                {
                  name: 'Amazfit<br/>智能手表 2 复仇者联盟系列限量版',
                  image: CDN_URL + '/images/category/watch/nexo-marvel.png',
                  link: '/products/nexo-marvel'
                },
                {
                  name: 'Amazfit<br>智能手表 2',
                  image: CDN_URL + '/images/category/watch/nexo.png',
                  link: '/products/nexo'
                },
                {
                  name: 'Amazfit<br>智能手表',
                  image: CDN_URL + '/images/category/watch/verge.png',
                  link: '/products/verge'
                },
                {
                  name: 'Amazfit<br>智能手表青春版',
                  image: CDN_URL + '/images/category/watch/verge-lite.png',
                  link: '/products/verge-lite'
                }
              ]
            },
            {
              title: '经典入门',
              products: [
                {
                  name: 'Amazfit<br/>Pop',
                  image: 'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/2_pro_01.png',
                  link: '/products/pop'
                },
                {
                  name: 'Amazfit<br/>米动手表青春版1s',
                  image: CDN_URL + '/images/category/watch/bip-s.png',
                  link: '/products/bip-s'
                },
                {
                  name: 'Amazfit<br/>米动手表青春版',
                  image: CDN_URL + '/images/category/watch/bip.png',
                  link: '/products/bip'
                },
                {
                  name: 'Amazfit<br/>米动手表青春版Lite',
                  image: CDN_URL + '/images/category/watch/bip-lite.png',
                  link: '/products/bip-lite'
                }
              ]
            },
            {
              title: '健康监测',
              products: [
                {
                  name: 'Amazfit<br/>米动健康手表',
                  image: CDN_URL + '/images/category/watch/health-watch.png',
                  link: '/products/health-watch'
                },
                {
                  name: 'Amazfit<br>智能手表 2 ECG',
                  image: CDN_URL + '/images/category/watch/nexo-ecg.png',
                  link: '/products/nexo?spec=1'
                }
              ]
            },
            {
              title: 'X 系列',
              products: [
                {
                  name: 'Amazfit X',
                  image: CDN_URL + '/images/category/watch/amazfit-x.png',
                  link: '/products/amazfit-x'
                }
              ]
            }
          ]
        },
        // {
        //   banner: CDN_URL + '/images/category/band/banner.jpg',
        //   banner_link: 'products/health-band-chip',
        //   products: [
        //     {
        //       title: '手环',
        //       products: [
        //         {
        //           name: 'Amazfit<br/>米动手环2',
        //           image: CDN_URL + '/images/category/band/band2.png',
        //           link: '/products/band2'
        //         },
        //         {
        //           name: 'Amazfit<br/>月霜手环',
        //           image: CDN_URL + '/images/category/band/moonbeam.png',
        //           link: '/products/moonbeam'
        //         },
        //         {
        //           name: 'Amazfit<br/>赤道手环',
        //           image: CDN_URL + '/images/category/band/equator.png',
        //           link: '/products/equator'
        //         },
        //         {
        //           name: '米动健康手环<br/>黄山1号版',
        //           image: CDN_URL + '/images/category/band/health-band-chip.png',
        //           link: '/products/health-band-chip'
        //         },
        //         {
        //           name: 'Amazfit<br/>米动健康手环 1S',
        //           image: CDN_URL + '/images/category/band/health-band-1s.png',
        //           link: '/products/health-band-1s'
        //         }
        //       ]
        //     }
        //   ]
        // },
        {
          banner: 'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/700380.png',
          banner_link: 'products/happya',
          products: [
            {
              title: '儿童手表',
              products: [
                {
                  name: 'Amazfit <br/>快乐鸭儿童健康手表',
                  image: 'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/136136.png',
                  link: '/products/happya'
                }
              ]
            }
          ]
        },
        {
          banner: CDN_URL + '/images/category/earphone/banner.jpg',
          banner_link: 'products/powerbuds',
          products: [
            {
              title: '耳机',
              products: [
                {
                  name: 'Amazfit <br/>PowerBuds Pro',
                  image: 'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/1.png',
                  link: '/products/powerbuds-pro'
                },
                {
                  name: 'Amazfit <br/>PowerBuds',
                  image: CDN_URL + '/images/category/earphone/powerbuds.png',
                  link: '/products/powerbuds'
                },
                {
                  name: 'Amazfit <br/>ZenBuds',
                  image: CDN_URL + '/images/category/earphone/zenbuds.png',
                  link: '/products/zenbuds'
                }
              ]
            }
          ]
        },
        {
          products: [
            {
              title: '配件',
              products: [
                {
                  name: 'Amazfit 表带<br/> 氟橡胶系列 轻盈透气款',
                  image: 'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/1_pro_01.png',
                  link: '/products/slim-breath'
                },
                {
                  name: 'Amazfit 表带<br/> 氟橡胶系列 轻薄款',
                  image: CDN_URL + '/images/category/accessory/fluorine-rubber-slim-band.png',
                  link: '/products/rubber-slim-wristbands'
                },
                {
                  name: 'Amazfit 表带<br/> 炫彩系列',
                  image: CDN_URL + '/images/category/accessory/colorful-silicone-strap.png',
                  link: '/products/silicone-wristbands'
                },
                {
                  name: 'Amazfit 表带<br/> 真皮系列',
                  image: CDN_URL + '/images/category/accessory/watch-strap-leather-series.png',
                  link: '/products/leather-wristbands'
                }
              ]
            }
          ]
        }
      ]
    }
  },
  created () {
    this.selectedCategory = this.indexClass
  },
  computed: {
    ...mapState([
      'indexClass'
    ])
  },
  methods: {
    ...mapActions(['editIndexClassFun']),
    chooseClass (e) {
      this.selectedCategory = e
      this.editIndexClassFun(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.category-page {
  background-color: #F3F3F3;
  min-height: 100vh;

  .side-nav {
    width: 100px;
    float: left;

    .item {
      line-height: 100%;
      font-size: 13px;
      padding-left: 10px;
      margin: 18.5px 0 39.5px 0;
      color: #666;
      border-left: 2px solid transparent;

      &.current {
        font-size: 15px;
        font-weight: bold;
        border-left: 2px solid #FBC72E;
      }
    }
  }

  .right-content {
    float: right;
    box-sizing: border-box;
    width: calc(100% - 100px);
    border-left: 1px solid rgba(51,51,51,0.2);
    margin-top: 12.5px;
    margin-bottom: 12.5px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .banner {
    border-radius:5px;
    overflow: hidden;

    img {
      width: 100%;
      display: block;
    }
  }

  .panel {
    margin-top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    padding: 0 10px 20px;
    background-color: #FFF;

    h2 {
      font-size: 13px;
      color: #333;
      border-left: 2px solid #333;
      line-height: 100%;
      padding-left: 7px;
      margin: 10px 5px 0 0;
    }

    .products {
      display: flex;
      flex-wrap: wrap;

      .item {
        flex-basis: 33.3333%;
        text-align: center;
        margin-top: 25px;

        img {
          height: 60px;
        }

        .name {
          margin-top: 10px;
          font-size: 10px;
          line-height: 12px;
          height: 24px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
